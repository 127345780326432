// Importing global scss variables
@use "../src/app/core/theme/se_theme";
@use "src/app/core/theme/components/se_loader";
@use "src/app/core/theme/se_variables" as *;

::-webkit-scrollbar-track {
  border: 5px solid white;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #6b72794b;
  border-radius: 10px;
}

button:focus {
  outline: 1px double;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  padding: 0 !important;
}
body {
  margin: 0;
}

label {
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;
}

// status classes

.cursor {
  cursor: pointer;
}
.open {
  color: $se-orange-1;
}
.bg-open {
  background-color: $se-gray-2;
}
.pending {
  color: $se-color-info;
  margin: 10px;
}
.success {
  color: $se-color-success;
  margin: 10px;
}
.bg-success {
  background-color: $se-color-success;
}
.bg-approved {
  background-color: $se-color-approved;
}
.success-below-threshold {
  color: $se-yellow-3;
  margin: 10px;
}
.bg-success-below-threshold {
  background-color: $se-yellow-3;
}
.error {
  color: $se-color-error;
  margin: 10px;
}
.bg-error {
  background-color: $se-color-error;
}
.light_error {
  color: $se-gray-5;
  margin: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

:focus-visible {
  outline-style: dashed;
  outline-width: 2px;
}

.p-progressbar-value {
  background: $se-bright-purple !important;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  font-size: 14px;
}

.pki-login-siemens {
  background: $se-purple !important;
  color: white !important;
}

#okta-sign-in.auth-container .button-primary {
  background: white !important;
  color: black !important;
}

.report-tooltip {
  font-size: 14px !important;
}

.mat-button-wrapper {
  display: flex !important;
}

.backdrop {
  background-color: $se-gray-5;
  filter: blur(60px);
}

.user-filter-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .user-filter {
    padding: 0px 10px;
    line-height: 1.5rem;
    color: dimgray;
  }
}
