@use "../se_variables" as *;

.se-body {
  .se-nav {
    .nav-item a {
      font-size: $se-size-nav-text;
      color: $se-white;
      float: left;
      position: relative;

      &:hover:not(.active) {
        color: $se-purple;
      }

      &.active::after {
        border-bottom: 5px solid $se-purple;
        bottom: -10px;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}
