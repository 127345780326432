@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.loader-shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  min-height: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.p-datatable-loading-overlay {
  display: none !important;
  min-height: 200px;
}

.p-datatable .p-datatable-loading-icon {
  display: none;
}
